<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Couverture de l'essai</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue table label="Couvertures autorisées" :value="protocol.couvertures_possibles" />
    <KeyValue table label="Couvertures interdites" :value="protocol.couvertures_interdites" />
  </div>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ProtocolSubItemCoverageExperiment',

  components: {
    KeyValue,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },

  data() {
  },

  methods: {
  },
}
</script>

<style scoped>
</style>
